import React, { Component } from 'react'
import Nav from '../components/Nav.js'

class Footer extends Component {
    render() {
        return (
            <div>
                 <footer>
                 
                </footer>
            </div>
        )
    }
}

export default Footer
